import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const latranquilite = () => (
  <Layout>
    <SEO title="Bananes Citronnées - La tranquilité" />
    <h3 className='underline-title'>La tranquilité</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Le dieu aux forces orogéniques<br />
        Fracture l'écorce terrestre<br />
        Le manteau pyrosphérique blessé<br />
        Se fracasse de trépan téméraire de la science<br />
        Le vacarme de tous les diables de la terre<br />
        S'engrouffre à travers la cassure de la croûte<br />
        Et trouble le calme chaud du barysphère<br />
        La planète en désintégration totale<br />
        Etouffe de haut degré géothermique<br />
        Du magma en fusion infernale<br />
        La tranquilité adultérée par la science<br/>
        Fait une escapade en ionosphère<br />
        Le cosmonaute casse-cou acrobate<br/>
        L'y poursuit et la viole ignoblement<br />
        Je déclare et certifie honnêtement<br />
        La mort certaine de la tranquilité
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 23 javnvier 1976</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default latranquilite
